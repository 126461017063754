<template>
	<div class="hangar_pages zui-bg-white">
		<!-- 按钮区域 -->
		<hangar :isPhone='isPhone' :buttonList="buttonList" @curBtn="clickBtn" :curBtn="curBtn"
			:title="$t('hangar.name')"></hangar>
		<!-- 返回按钮 -->
		<div id="back" v-show="isPhone" @click="back"
			style="z-index: 999; position: fixed;top: 50%;left: 0;width: 50px;height: 50px;border-radius: 50%;">
			<img src="../assets/image/back_phone.png" style="height: 100%;width: 100%;">
		</div>
		<!-- 输入框搜索区域 -->
		<div class="hangar_pages_search flex_column" :class="language=='en' ? 'hangar_pages_search_en ': ''">
			<div class="zui-flex-1" :style="'width:' + screenWidth + 'px'">
				<div class="demo-input-suffix flex_row" style="width:96%; height:100%">
					<div class="zui-flex-row search-input">
						<div class="zui-flex-row zui-justify-end  zui-flex-1 zui-align-center">
							<div class="fontsize jinColor">{{$t('search.planeBrand')}}</div>
							<el-select v-model="brand" class="elinput" @change="changeBrand"
								:placeholder="$t('search.all')" size="mini">
								<el-option class="jinColor" v-for="item in options1" :key="item.value"
									:label="item.label" :value="item.value">
								</el-option>
							</el-select>
						</div>
						<div class="zui-flex-row zui-justify-end zui-flex-1 zui-align-center">
							<div class="fontsize jinColor">{{$t('search.seatNum')}}</div>
							<el-select v-model="seat" @change="changeSeat" class="elinput"
								:placeholder="$t('search.all')" size="mini">
								<el-option class="jinColor" v-for="item in options2" :key="item.value"
									:label="item.label" :value="item.value">
								</el-option>
							</el-select>
						</div>
					</div>
					<div class="zui-flex-row  search-input">
						<div class="zui-flex-row zui-justify-end zui-flex-1 zui-align-center">
							<div class="fontsize jinColor">{{$t('search.airRange')}}</div>
							<el-select v-model="distance" class="elinput" @change="changeDistance"
								:placeholder="$t('search.all')" size="mini">
								<el-option class="jinColor" v-for="item in options3" :key="item.value"
									:label="item.label" :value="item.value">
								</el-option>
							</el-select>
						</div>
						<div class="zui-flex-row zui-justify-end zui-flex-1 zui-align-center">
							<div class="fontsize jinColor">{{$t('search.price')}}</div>
							<el-select v-model="price" class="elinput" @change="changePrice"
								:placeholder="$t('search.all')" size="mini">
								<el-option class="jinColor" v-for="item in options4" :key="item.value"
									:label="item.label" :value="item.value">
								</el-option>
							</el-select>
						</div>
					</div>
				</div>
			</div>
			<div class="zui-flex-1 flex_row zui-m-t-20">
				<el-button type="info" @click="search" style="width: 180px;border-radius: 12px;" plain class="moreBtn1"
					size="mini">{{ $t('search.search') }}</el-button>
			</div>
		</div>
		<!-- 两行新品 -->
		<div>
			<el-row class="hangar_row">
				<el-col v-for="(item, index) in listData" :key="index" class="list-item" :xs="12" :sm="8" :md="8"
					:xl="8" :lg="8">
					<div class="grid-content img-list bg-purple zui-overflow-hidden zui-relative" @click="toDetail(item)" :id="'id'+item.id.toString()">
						<el-image class="plane-img zui-border-radius-small" style="width: 100%;" :src="item.bannerPictures|swiperImg"
							fit="fill">
						</el-image>
						<div class="text_2_line zui-p-t-10 zui-text-center zui-p-x-10 planeTile">{{item.title}}</div>
						<div v-if="isComparsion" class="addComparsion" @click.stop="comparsion(item)">
							{{isOnArr(item.id) ?$t('hangar.off_contrast'):$t('hangar.on_contrast')}}
						</div>
						<div class="image-tag2" v-if="item.sale=='Y'" style="position: absolute;top: 0px;left: 0px;">
							<!-- <img :src="item.sale=='Y'?'https://www.astroairs.com/public/images/sell_out.png':''" style="width:20%;"> -->
							<div class="tag">Sold Out</div>
						</div>
					</div>
				</el-col>
			</el-row>
			<div class="moreBox" style="margin-top:20px">
				<div class="more-load" v-if="isMoreLoad" @click="scrollLoadMore">
					<span v-if="loadingImg">{{$t('common.moreText')[1]}}</span>
					<span v-else>{{$t('common.moreText')[0]}}</span>
				</div>
				<div class="more-load" v-if="loadLastText">{{$t('common.moreText')[2]}}</div>
			</div>
		</div>
		<div class="zanwei"></div>
		<!-- 对比图标 -->
		<div :class="[isComparsion?'current comparsion':'comparsion']" @click="goToComparsion">
			<div :class="language =='en' ? 'comparsion_en' : ''">
				{{isComparsion?$t('hangar.contrast'):$t('hangar.open_contrast')}}</div><span
				v-if="comparsionNum > 0">{{comparsionNum}}</span>
		</div>
		<!-- 填表 -->
	</div>
</template>
<script>
	import $config from "../api/config.js"
	import hangar from '../components/hangarCom';
	import {
		getAirPlaneType,
		getAirInfo,
		getBrandAndType,
		// listByFilter
	} from '../api/api';
	import {
		mapState,
	} from 'vuex';
	var filterMap = {
		seat: [{
				name: '全部',
				min: '',
				max: ''
			},
			{
				name: '12座以下',
				min: '',
				max: 12
			},
			{
				name: '12-16座',
				min: 12,
				max: 16
			},
			{
				name: '16座以上',
				min: 16,
				max: ''
			}
		],
		distance: [{
				name: '全部',
				min: '',
				max: ''
			},
			{
				name: '5000千米以下',
				min: '',
				max: 5000
			},
			{
				name: '5000-8000千米',
				min: 5000,
				max: 8000
			},
			{
				name: '8000-11000千米',
				min: 8000,
				max: 11000
			},
			{
				name: '11000千米以上',
				min: 11000,
				max: ''
			}
		],
		price: [{
				name: '全部',
				min: '',
				max: ''
			},
			{
				name: '$ 1000万以下',
				min: '',
				max: 1000
			},
			{
				name: '$ 1000-5000万',
				min: 1000,
				max: 5000
			},
			{
				name: '$ 5000万+',
				min: 5000,
				max: ''
			}
		]
	}
	var filterMap_en = {
		seat: [{
				name: 'whole',
				min: '',
				max: ''
			},
			{
				name: 'Less than 12 seats',
				min: '',
				max: 12
			},
			{
				name: '12-16 seats',
				min: 12,
				max: 16
			},
			{
				name: 'More than 16 seats',
				min: 16,
				max: ''
			}
		],
		distance: [{
				name: 'whole',
				min: '',
				max: ''
			},
			{
				name: 'Below 5000 km',
				min: '',
				max: 5000
			},
			{
				name: '5000-8000 km',
				min: 5000,
				max: 8000
			},
			{
				name: '8000-11000km',
				min: 8000,
				max: 11000
			},
			{
				name: 'Over 11000 km',
				min: 11000,
				max: ''
			}
		],
		price: [{
				name: 'whole',
				min: '',
				max: ''
			},
			{
				name: 'Less than $10 million',
				min: '',
				max: 1000
			},
			{
				name: '$10-50 million',
				min: 1000,
				max: 5000
			},
			{
				name: 'Over $50 million',
				min: 5000,
				max: ''
			}
		]
	}
	export default {
		data() {
			return {
				isPhone: false,
				buttonList: [],
				curBtn: -1,
				// input1: '',
				// input2: '',
				screenWidth: '',
				bannerHeight: '',
				centerHeight: '',
				cardType: 'card',
				listData: [],
				queryData: {
					// planeBrand: '',
					// planeType: '',
					steatsNum_begin: '',
					steatsNum_end: '',
					maxRange_begin: '',
					maxRange_end: '',
					price_end: '',
					price_begin: '',
				},
				brand: '',
				distance: '',
				seat: '',
				price: '',
				definePafeSize: 9,
				options1: [],
				options2: [{
					value: 0,
					label: '全部'
				}, {
					value: 1,
					label: '12座以下'
				}, {
					value: 2,
					label: '12-16座'
				}, {
					value: 3,
					label: '16座以上'
				}],
				options3: [{
					value: 0,
					label: '全部'
				}, {
					value: 1,
					label: '5000千米以下'
				}, {
					value: 2,
					label: '5000-8000千米'
				}, {
					value: 3,
					label: '8000-11000千米'
				}, {
					value: 4,
					label: '11000千米以上'
				}],
				options4: [{
					value: 0,
					label: '全部'
				}, {
					value: 1,
					label: '$ 1000万以下'
				}, {
					value: 2,
					label: '$ 1000-5000万'
				}, {
					value: 3,
					label: '$ 5000万以上'
				}],
				searchQuery: {},
				isComparsion: false,
				comparsionIdArr: [],
				comparsionIds: [],
				comparsionNum: 0,
				filterMap: '',
			};

		},
		computed: {
			...mapState(["language"])

		},
		filters: {
			swiperImg: function(value) {
				// console.log(value);
				if ((value.search("http") != -1) && (value.search("https") != -1)) {
					return value
				} else {
					return $config.apiUrl + '/' + value
				}
			}
		},
		created: function() {
			if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
				this.cardType = 'a';
				this.isPhone = true
			} else {
				this.cardType = 'card';
				this.isPhone = false
			}
			let params = this.$route.params;
			// console.log('参数---aa',params)
			if (params.search) {
				this.searchQuery.flag='params'
				this.searchQuery = params.search
				this.queryData.type = params.search.type
				this.curBtn = params.search.type
				// this.clickBtn(0)
				//this.queryData.distance = params.search.type
				// this.queryData.type = params.search.type + 1
				this.changeSeat(this.searchQuery.seat)
				this.changeDistance(this.searchQuery.distance)
				this.changePrice(this.searchQuery.price)
				// this.changeBrand(this.searchQuery.seat)
			//console.log('带参数回退')
			} else {
	
				this.searchQuery.flag='aside'
				let id = Number(params.id) - 1
				this.searchQuery = {
					brand: 0,
					type: id,
					distance: 0,
					price: 0,
					seat: 0
				}
				//this.queryData.type =id
				this.curBtn = id
				//console.log('aside-')
			}

			this.getData();
			this.filterMap = filterMap

		},
		watch: {
			language(event) {
				this.setEn(event)
			},
			$route(from) {
				// 监听路由变化, 实现类似 小程序的 onShow 事件
				if (from.params.id) {
					this.curBtn = from.params.id - 1;
					this.searchQuery={}
				} else {
					this.curBtn = 0;

				}

				this.queryData.planeType = this.buttonList[this.curBtn].typeId
				this.queryData.type = this.buttonList[this.curBtn].typeId
				this.definePageNum = 1;
				this.hangars();

				//console.log(this.queryData)


			},
			comparsionIdArr(newV) {
				this.comparsionNum = newV.length
				if (this.comparsionNum == 0) {
					this.isComparsion = false
				}

			}
		},
		methods: {
		
			setEn(e) {
				if (e == 'en') {
					filterMap = filterMap_en
				} else {
					filterMap = this.filterMap
				}

				this.options2 = [{
					value: 0,
					label: this.$t('search.all')
				}, {
					value: 1,
					label: this.$t('search.mesg1')
				}, {
					value: 2,
					label: this.$t('search.mesg2')
				}, {
					value: 3,
					label: this.$t('search.mesg3')
				}]
				this.options3 = [{
					value: 0,
					label: this.$t('search.all')
				}, {
					value: 1,
					label: this.$t('search.mesg4')
				}, {
					value: 2,
					label: this.$t('search.mesg5')
				}, {
					value: 3,
					label: this.$t('search.mesg6')
				}, {
					value: 4,
					label: this.$t('search.mesg7')
				}]
				this.options4 = [{
					value: 0,
					label: this.$t('search.all')
				}, {
					value: 1,
					label: this.$t('search.mesg8')
				}, {
					value: 2,
					label: this.$t('search.mesg9')
				}, {
					value: 3,
					label: this.$t('search.mesg10')
				}]
			},
			isOnArr(id = 0) {
				return this.comparsionIdArr.includes(id);
			},
			comparsion(id = {}) {
				// console.log(this.comparsionIdArr);
				if (this.isOnArr(id.id)) {
					let index = this.comparsionIdArr.indexOf(id.id);
					if (index != -1) {
						this.comparsionIdArr.splice(index, 1)
					}
					let index1 = this.comparsionIds.indexOf(id);
					if (index != -1) {
						this.comparsionIds.splice(index1, 1)
					}
				} else {
					this.comparsionIds.push(id)
					this.comparsionIdArr.push(id.id)
				}
				// console.log(this.comparsionIds);
			},
			goToComparsion() {
				if (this.isComparsion == false) {
					this.isComparsion = true;
					return;
				}
				if (this.isComparsion == true && this.comparsionNum < 2 && this.comparsionNum > 0) {
					this.$message({
						message: this.$t('search.mesg'),
						showClose: true,
						type: 'error',
						customClass: 'hahaha',
						duration: 2000,
						offset: 20
					});
					return;
				}
				if (this.comparsionNum >= 2) {
					this.$router.push({
						name: 'comparison',
						params: {
							ids: JSON.stringify(this.comparsionIds)
						}
					});
				} else {
					this.isComparsion = false;
					this.comparsionIds = [];
					this.comparsionIdArr = [];
				}

			},
			search() {
				this.definePageNum = 1;
				this.hangars();
			},
			async getData() {
				//获取品牌
				await getBrandAndType().then(res => {
					// console.log(res);
					if (res.code == 200) {
						let data = res.result.brandVoList;
						let types = [{
							value: '',
							label: this.$t('search.all')
						}];
						let brandNoArr = [];
						this.$zui.arrayEach(data, item => {
							let temp = {};
							temp.label = item.brandName;
							temp.value = item.brandId;
							let temp2 = [];
							if (item.planeTypeVoList.length > 0) {
								this.$zui.arrayEach(item.planeTypeVoList, v => {
									let temp1 = {};
									temp1.label = v.brandName;
									temp1.value = v.brandId;
									temp2.push(temp1);
								});
							}
							types.push(temp);
							brandNoArr.push(temp2);
						});
						this.options1 = types;
						// console.log(this.options1);
						// console.log(this.searchQuery.brand);
						if (this.searchQuery.brand >= 0) {
							this.queryData.planeBrand = this.options1[this.searchQuery.brand]['value']
							this.queryData.brandId = this.options1[this.searchQuery.brand]['value']
							this.brand = this.options1[this.searchQuery.brand]['label']
						}

						this.brandNoArr = brandNoArr;
						// console.log(this.options);
					} else {
						this.$message({
							message: res.message,
							showClose: true,
							type: 'error',
							customClass: 'hahaha',
							duration: 2000,
							offset: 20
						});
						//console.log(res.message);
					}
				});
				//获取飞机类型
				await getAirPlaneType().then(res => {
					// console.log(res);
					if (res.code == 200) {
						let data = res.result.records;

						this.$zui.arrayEach(data, (item, index) => {
							let temp = {};
							temp.name = item.typeName;
							temp.id = index;
							// temp.typeId = item.id;
							temp.typeId = item.typeValue;
							this.buttonList.push(temp);
							if (this.searchQuery.type == index) {
								this.queryData.planeType = item.typeValue;
								this.queryData.type = item.typeValue;
							}
						});

						this.curBtn = this.searchQuery.type > -1 ? this.searchQuery.type : -1;

					} else {
						this.$message({
							message: res.message,
							showClose: true,
							type: 'error',
							customClass: 'hahaha',
							duration: 2000,
							offset: 20
						});
						// console.log(res.message);
					}
				});
				this.search();
			},
			//加载更加
			scrollLoadMore() {
				// 防止多次加载
				if (this.loadingImg) {
					return;
				}
				//开启加载动画
				this.loadingImg = true;
				//调取 API
				this.hangars();
			},
			hangars() {

				this.queryData.pageNo = this.definePageNum;
				this.queryData.pageSize = this.definePafeSize;
				this.queryData._t = Date.parse(new Date());
				var parms = this.queryData
				var sendata = {
					pageNo: parms.pageNo,
					pageSize: parms.pageSize,
					brandId: parms.brandId,
					type: parms.type > -1 ? parms.type : null,
					distance: {
						maxDistance: parms.maxRange_end,
						minDistance: parms.maxRange_begin
					},
					price: {
						maxPrice: parms.price_end,
						minPrice: parms.price_begin
					},
					steatsNum: {
						maxSeat: parms.steatsNum_end,
						minSeat: parms.steatsNum_begin
					}
				}
				getAirInfo(sendata).then(res => {
					//console.log(res);
					if (res.code == 200) {
						//分页 - S
						//判断是否是第一页
						this.totals = res.result.total; //总数据 
						if (this.definePageNum >= 2) {
							//追加数据
							this.listData = this.listData.concat(res.result.records)
						} else {
							this.listData = res.result.records
						}

						if (this.totals - this.definePageNum * this.definePafeSize > 0) {
							this.isMoreLoad = true; //显示 加载更多 按钮
							this.loadLastText = false; //显示 没有更多数据了 按钮
						} else {
							this.isMoreLoad = false; //不再显示 加载更多 按钮
							this.loadLastText = true; //显示 没有更多数据了 按钮
						}
						//分页++
						this.definePageNum++;
						// 加载完成后停止加载动画
						this.loadingImg = false;
						//分页 - e

					} else {
						this.$message({
							message: res.message,
							showClose: true,
							type: 'error',
							customClass: 'hahaha',
							duration: 2000,
							offset: 20
						});
						// console.log(res.message);
					}
				});
			},
			clickBtn(id) {
				this.curBtn = id;
				this.queryData.planeType = this.buttonList[id].typeId
				this.queryData.type = this.buttonList[id].typeId
				this.searchQuery.planeType = id
				this.searchQuery.type =id
				this.definePageNum = 1;
				this.searchQuery.flag='params'
				this.hangars();

				// console.log(this.buttonList[id].typeId);

			},
			setSize: function() {
				// 通过浏览器宽度(图片宽度)计算高度
				if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
					this.bannerHeight = (320 / this.screenWidth) * this.screenWidth;
				} else {
					this.bannerHeight = (500 / this.screenWidth) * this.screenWidth;
				}

				this.centerHeight = (1080 / this.screenWidth) * this.screenWidth;
			},
			// 跳转详情页
			toDetail(item) {
				localStorage.setItem('posi', item.id)
				this.$router.push({
					name: 'airplaneDetail',
					params: {
						planeInfo: item,
						oquery:this.searchQuery
					}
				});
			},
			to_scroll() {
				let posi = 'id' + localStorage.getItem('posi')
				if (posi) {
					var dom = document.getElementById(posi)
					//console.log('获取到dom了',dom)
					if (dom) {
						dom.scrollIntoView({
							behavior: "smooth", // 平滑过渡
							block: "center" // 滚到屏幕中间
						})
					}
			
				}
				localStorage.setItem('posi', '')
				//清楚 posi的值，以防再次载入页面时发生滚动
			},
			resetForm: function() {
					//清空表单
					for (let k in this.formData) {
						this.formData[k] = '';
					}
				}
				//座位
				,
			changeSeat(e) {
				// console.log(e);
				this.queryData.steatsNum_begin = filterMap['seat'][e]['min'];
				this.queryData.steatsNum_end = filterMap['seat'][e]['max'];
				this.seat = filterMap['seat'][e]['name']
			},
			changeDistance(e) {
				// console.log(e);
				this.queryData.maxRange_begin = filterMap['distance'][e]['min'];
				this.queryData.maxRange_end = filterMap['distance'][e]['max'];
				this.distance = filterMap['distance'][e]['name']
			},
			changePrice(e) {
				// console.log(e);
				this.queryData.price_begin = filterMap['price'][e]['min'];
				this.queryData.price_end = filterMap['price'][e]['max'];
				this.price = filterMap['price'][e]['name']
				//console.log(filterMap['price'])
			},
			changeBrand(e) {
				// console.log(e);
				this.queryData.planeBrand = e;
				this.queryData.brandId = e;
			}
		},
		updated() {
			setTimeout(() => {
			this.to_scroll()
			},500)
		},
		mounted() {
			setTimeout(() => {
			this.to_scroll()
			},500)
			// 首次加载时设置data去选项语言
			this.setEn(this.language)
			// 首次加载时,需要调用一次
			this.screenWidth = window.innerWidth;
			// console.log(this.screenWidth);
			// 窗口大小发生改变时,调用一次
			this.setSize();
			// 窗口大小发生改变时,调用一次
			window.onresize = () => {
				this.screenWidth = window.innerWidth;
				this.setSize();
			};
			this.scrollLoadMoreEvn();
		},
		components: {
			hangar
		}
	};
</script>
<style lang="scss" scoped>
	@import '../assets/scss/config.scss';
	@import '../assets/scss/mixin.scss';
	.img-list{
		position: relative;
	}
	.image-tag2{
		position: absolute;
		top: 10px;
		left: 10px;
	}
	.tag{
		padding:5px 20px;
		color: white;
		background-color:#000;
	}
	.el-carousel__mask {
		background-color: transparent !important;
	}

	// flex_column
	.hangar_pages {
		width: 100%;
		overflow: hidden;

		.hangar_pages_top {
			width: 100%;
			height: 138px;
			background: url('../assets/image/15.jpg') no-repeat center;
			background-size: 100% 100%;

			div {
				color: $colorJ;
				font-size: $fontD;
			}
		}
	}

	.hangar_pages_search_en {
		height: 180px !important;
	}

	.hangar_pages_search {
		width: 100%;
		height: 138px;
		background-color: white;
		box-shadow: 0 15px 20px 4px #d1d1d1;

		.elinput {
			width: 125px;
			height: 23px;
			margin-left: 10px;
		}

		.demo-input-suffix {
			margin-top: 20px;
			flex-wrap: wrap;

			.fontsize {
				font-size: 15px;
				margin-top: 3px;
				// width:45px;

				text-align: right;
			}
		}

		.bot_btn {
			width: 200px;
			background-color: #000000;
			color: $colorG;
		}

		/deep/ .el-input__inner {
			background-color: transparent !important;
			border: 1px solid #dbb96e !important;
			color: #dbb96e !important;
		}

		/deep/ .el-select .el-input .el-select__caret {
			color: #dbb96e !important;
		}

		.el-button--info.is-plain:focus,
		.el-button--info.is-plain:hover {
			background-color: white;
			border: 1px solid #dbb96e !important;
			color: #dbb96e !important;
		}
	}

	.bottom_text {
		text-align: right;
	}

	// 新品机型
	.newModels {
		position: relative;
		height: 370px;

		.conter {
			position: inherit;
			width: 80%;
			font-size: 10px;
			top: 104px;
			font-size: 10px;
			left: 53%;
			transform: translate(-50%, 0%);

			img {
				width: 100%;
				height: 100%;
			}

			.cont_mid {
				width: 100%;
			}

			.cont_bottom {
				width: 100%;
				text-align: right;
			}
		}
	}

	.comparsion_en {
		font-size: 10px !important;
	}

	// 推荐机型
	.RecommendedModels {
		width: 100%;
		// height: 20px;
		position: relative;
		margin-bottom: 50px;
	}

	.heng {
		width: 900px;
		background-color: #000000;
		height: 1px;
		margin: 0 auto;
		position: absolute;
		top: 32px;
		left: 50%;
		transform: translate(-50%, -25%);
	}

	.kuang {
		width: 133px;
		height: 23px;
		line-height: 0.32rem;
		text-align: center;
		background-color: #000000;
		color: $colorG;
		position: absolute;
		top: 25px;
		left: 50%;
		transform: translate(-50%, -25%);
	}

	.hangar_tj {
		width: 100%;
		text-align: center;
		margin: 20px auto;
	}

	.hangar_h1 {
		width: 80%;
		padding: 20px 0;
		font-size: 25px;

	}

	.btnColor {
		width: 147px;
		height: 32px;
	}

	.act {
		background-color: $colorJ;
		color: $colorG;
	}

	.el-carousel-item {
		img {
			width: 100%;
			height: 100%;
		}
	}

	.el-row {
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.el-col {
		border-radius: 4px;
	}

	.grid-content {
		border-radius: 4px;

		border-radius: 4px;
		// padding: 0 10px;
		box-sizing: border-box;
	}

	.comparsion {
		position: fixed;
		right: 7%;
		bottom: 20%;
		background-color: white;

		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 14px;
		width: 60px;
		height: 60px;
		border-radius: 60px;
		box-shadow: 0 0 4px 5px #cecece;
		cursor: pointer;
		z-index: 9999;

		&:hover {
			box-shadow: 0 0 4px 5px #DBB96E;
			color: #DBB96E;
		}

		&.current {
			box-shadow: 0 0 4px 5px #DBB96E;
			color: #DBB96E;
		}

		span {
			padding: 4px 8px;
			font-size: 12px;
			background-color: red;
			color: white;
			border-radius: 18px;
			position: absolute;
			right: 0;
			bottom: 0;
		}
	}

	.addComparsion {
		padding: 3px 5px;
		font-size: 10px;
		border-radius: 3px;
		border: 1px solid #DBB96E;
		color: #DBB96E;
		position: absolute;
		background-color: white;
		cursor: pointer;
		top: 5px;
		right: 15px;
	}

	//飞机宽高
	/deep/ .plane-img {
		padding-top: 60%;
		width: 100%;
		position: relative;

		img {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
		}
	}

	.row-bg {
		padding: 10px 0;
		background-color: #f9fafc;
	}

	.zanwei {
		width: 100%;
		height: 30px;
		background-color: #00000000;
	}

	.hangar_row {
		width: 80%;
		font-size: 12px;
		padding-top: 25px;
		margin: auto;
	}

	.bottom_input {
		background-image: url('../assets/image/18.png');
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		width: 100%;
		margin: 10px auto 0;
		height: 450px;
		text-align: center;
		font-size: 12px;

		.bottom_input_top {
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			height: 30px;
			margin-bottom: 35px;
			font-weight: bold;

			p {
				font-size: 21px;
				font-family: MicrosoftYaHei;
			}
		}
	}

	.elel_input {
		width: 100%;
		height: 19px;
		margin: 10px 20px;
	}

	.search-input {
		width: 520px;
	}

	.list-item {
		margin-bottom: 40px;
		padding: 0 10px;
		cursor: pointer;
	}

	.RecommendedModels_sm {
		margin-top: 20px;
	}

	.hangar_tj_sm {
		background-image: url('../assets/image/17.jpg');
		background-repeat: no-repeat;
		background-size: cover;
		width: 100%;
		height: 30px;
		position: relative;
	}

	.kuang_sm {
		font-size: 14px;
		color: #daba6d;
		position: absolute;
		left: 6%;
		bottom: 5%;
	}

	.kuang_sm::after {
		content: '';
		width: 0;
		height: 0;
		position: absolute;
		right: -20px;
		bottom: 4px;
		border-top: solid 6px transparent;
		border-right: solid 6px #daba6d;
		border-left: solid 6px transparent;
		/* 黑色大三角形 */
		border-bottom: solid 6px #daba6d;
	}

	.carousel-box {
		width: 80%;
		margin: 0 auto;
	}

	.planeType {
		font-size: 18px;
		font-family: MicrosoftYaHeiLight;
	}

	.planeTile {
		font-size: 12px;
		font-family: MicrosoftYaHeiLight;
		height: 60px;
	}

	.planePrice {
		font-size: 14px;
		font-family: MicrosoftYaHeiLight;
	}

	.moreBtn1 {
		text-align: center;
		padding: 5px 10px;
		box-sizing: border-box;
		color: #dab96b;
		font-size: 14px;
		border-radius: 2px;
		border: 1px solid #dab96b;
		background-color: transparent;
	}

	.parallelogram2 {
		width: 150px;
		height: 20px;
		-webkit-transform: skew(60deg);
		-moz-transform: skew(60deg);
		-o-transform: skew(60deg);
		-ms-transform: skew(60deg);
		transform: skew(60deg);
		border-top: 1px solid #DBB956;
		border-right: 2px solid #e3be59;
		overflow: hidden;

	}

	.parallelogram3 {
		flex: 1;
		border-bottom: 1px solid #DBB956;
		overflow: hidden;
		margin-left: 15px;
	}

	/deep/ .el-carousel__arrow {
		background-color: white !important;
		color: #dbb96e !important;
		box-shadow: 0 0 8px 2px #ececec !important;
		border: 1px solid #dbb96e !important;
		font-size: 18px !important;
	}



	//平板
	// @media only screen and (max-width: 768px) {
	// 	.search-input {
	// 		width: 480px;
	// 	}

	// 	.list-item {
	// 		margin-top: 20px;
	// 	}
	// }

	// 手机适配
	@media screen and (max-width: 960px) {
		.image-tag2{
			top: 5px !important;
			left: 15px !important;
			img{
				width: 25% !important;
			}
		}
		.comparsion_en {
			transform: scale(0.8);
		}

		.plane-img {
			height: 110px;
		}

		.search-input {
			// width: 340px;
		}

		.list-item {
			margin-bottom: 10px;
		}

		// 占位DIV
		.zanwei {
			width: 100%;
			height: 20px;
			background-color: #00000000;
		}

		//
		.hangar_row {
			width: 100%;
			margin: auto;
		}

		.grid-content {
			margin: 0;
		}

		// 新品机型底下文字
		.bottom_text {}

		.hangar_pages_search {
			.flex_row {
				flex-wrap: wrap;
			}

			width: 100%;
			height: 138px;
			background-color: $colorE;

			.elinput {
				width: 100px;
				height: 23px;
				margin-left: 10px;
			}

			.demo-input-suffix {
				margin-top: 20px;

				.fontsize {
					font-size: 10px;
				}
			}

			.bot_btn {
				background-color: #000000;
				color: $colorG;
			}
		}

		/deep/ .carousel-box {
			width: 95%;
		}

		.planeTile {
			height: 50px;
		}

		.planePrice {
			font-size: 12px;
			padding-top: 0;
			font-family: MicrosoftYaHeiLight;
		}
	}

	.contact {
		background: #d9b86b;
		border: #d9b86b;
		color: white;
	}
</style>
